import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";

// Define the type for the content data
interface BannerContent {
  type: string; // "video" or "image"
  videoUrl: string; // URL for video or image
  link: string; // External link
}

const HomeBanner: React.FC = () => {
  const [contentData, setContentData] = useState<BannerContent[]>([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://edexawebsiteapi.io-world.com/explorerBanner");
        const result:any = await response.json();

        if (result.status === 200) {
          const formattedData = result.data.map((item: any) => ({
            type: item.bannerType,
            videoUrl: item.bannerImg,
            link: item.externalLink,
          }));
          setContentData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentContentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
    }, 8000);

    return () => clearInterval(intervalId);
  }, [contentData]);

  const currentContent = contentData[currentContentIndex];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="125px"
      width="100%"
      bg="transparent"
    >
      <Box
        width="80%"
        maxWidth="800px"
        overflow="hidden"
        borderRadius="10px"
        boxShadow="none !important"
      >
        {currentContent && currentContent.type === "video" ? (
          <video
            src={currentContent.videoUrl}
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => window.open(currentContent.link, "_blank")}
          ></video>
        ) : (
          currentContent && (
            <img
              src={currentContent.videoUrl}
              alt="carousel content"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "auto",
                cursor: "pointer",
              }}
              onClick={() => window.open(currentContent.link, "_blank")}
            />
          )
        )}
      </Box>
    </Box>
  );
};

export default HomeBanner;
